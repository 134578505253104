<script>
import {mapActions, mapState} from "vuex";
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "PredictList",
  data(){
    return{
      studyLevelId: 1,
      studyCourse: 0,
      departmentId: 0,
      educationProgramId: 0,
      isDepartmentHead: decryptCheckRole('departmentHead'),

      studyCourses: [
        {
          value: 1,
          name: '1'
        },
        {
          value: 2,
          name: '2'
        },
        {
          value: 3,
          name: '3'
        },
        {
          value: 4,
          name: '4'
        },
      ],
    }
  },
  watch:{
    async studyCourse(){
      this.studyCourse && this.isDepartmentHead ? this.departmentId = getCookie('DEPARTMENT_ID') : ''
      if (this.isDepartmentHead){
        if (this.studyCourse && this.educationProgramId){
          await this.getPredict()
        } else {
          await this.getPredict()
          await this.getEducationPrograms()
        }
      } else {
        await this.getPredict()
      }
    },
    async departmentId(){
      if (this.studyCourse && this.departmentId) {
        await this.getPredict()
        await this.getEducationPrograms()
      }
    },
    async educationProgramId(){
      if (this.studyCourse && this.departmentId && this.educationProgramId) {
        await this.getPredict()
      }
    },
  },
  computed: {
    ...mapState('departments', ['departments_form']),
    ...mapState('predictList', ['predictList', 'education_programs']),
    ...mapState('adviserChoose', ['adviserChoose_form']),

  },
  methods: {
    ...mapActions('departments', ['GET_DEPARTMENTS_DATA']),
    ...mapActions('predictList', ['GET_PREDICT', 'GET_EDUCATION_PROGRAMS_BY_DEPARTMENT_ID']),
    ...mapActions('adviserChoose', ['GET_STUDY_LEVELS']),
    async getEducationPrograms(){
      await this.GET_EDUCATION_PROGRAMS_BY_DEPARTMENT_ID({
        study_course: this.studyCourse,
        study_level_id: this.studyLevelId,
        by_department: this.departmentId
      })
    },
    async getPredict(){
      await this.GET_PREDICT({
        study_course: this.studyCourse,
        department_id: this.departmentId,
        education_program_id: this.educationProgramId
      })
    },
  },
  async mounted() {
    await this.GET_DEPARTMENTS_DATA()
    await this.GET_STUDY_LEVELS()
    this.isDepartmentHead ? await this.getPredictByDepartmentId(getCookie('DEPARTMENT_ID')) : await this.GET_PREDICT({})
  }
}
</script>

<template>
  <div class="container">
    <h2 class="text-center mt-3">Прогноз отчисляемости</h2>
    <div class="row mt-4">
      <label class="col-md-3 col-form-label">Курс</label>
      <div class="col-md-9">
        <select class="form-control form-select" v-model="studyCourse">
          <option v-for="(sCourse, sCourseIndex) in [{value: 0, name:'Выберите курс'},...studyCourses]"
                  :key="sCourseIndex"
                  :value="sCourse.value">
            {{ sCourse.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-4" v-if="!isDepartmentHead && (studyLevelId && studyCourse)">
      <label class="col-md-3 col-form-label">Кафедра</label>
      <div class="col-md-9">
        <select class="form-control form-select" v-model="departmentId">
          <option
              v-for="(item, index) in [{id: 0, name_ru: 'Выберите кафедру'}, ...departments_form.departmentsInfos]"
              :value="item.id"
              :key="index">{{ item.name_ru }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-4" v-if="studyLevelId && studyCourse && departmentId">
      <label class="col-md-3 col-form-label">Образовательная программа</label>
      <div class="col-md-9">
        <select class="form-control form-select" v-model="educationProgramId">
          <option
              v-for="(item, index) in [{id: 0, education_speciality_code: 'Выберите', education_speciality_name: 'образовательную', language_name: 'программу'}, ...education_programs]"
              :value="item.id"
              :key="index">
            {{ item.education_speciality_code }}
            {{ item.education_speciality_name }}
            {{ item.language_name }}
          </option>
        </select>
      </div>
    </div>

    <DataTable :value="predictList" :paginator="true" :rows="10" v-if="predictList.length > 0"
               paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
               :rowsPerPageOptions="[10,20,50]" class="mt-3"
               currentPageReportTemplate="Показано {last} из {totalRecords}"
               stripedRows responsiveLayout="scroll">
      <Column header="№">
        <template #body="{index}">
          {{index+1}}
        </template>
      </Column>
      <Column field="fio" header="ФИО"></Column>
      <Column field="barcode" header="Баркод"></Column>
      <Column field="education_speciality_name" header="ОП"></Column>
      <Column header="Прогноз">
        <template #body="{data}">
          <Knob
            v-model="data.probability" :min="0" :max="1" disabled
            :valueTemplate="(Number(data.probability)*100).toFixed(0)+`%`"
          />
        </template>
      </Column>
      <Column header="Действия">
        <template #body="{data}">
          <router-link :to="'/student-transcript?barcode=' + data.barcode" target="_blank"
                       class="text-decoration-none">
            Транскрипт
          </router-link>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<style scoped>

</style>